const Types = {
  SET_CUSTOM_FIELDS_OPEN: 'SET_CUSTOM_FIELDS_OPEN',
  SET_PUBLISHING_CHANNELS_OPEN: 'SET_PUBLISHING_CHANNELS_OPEN',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_LOGIN_SETTINGS: 'SET_LOGIN_SETTINGS',
  SET_PROFILE: 'SET_PROFILE',
  SET_FAVORITES: 'SET_FAVORITES',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  REMOVE_PROFILE: 'REMOVE_PROFILE',
  SET_SORT: 'SET_SORT',
  SET_SEARCH_OPTIONS: 'SET_SEARCH_OPTIONS',
  TOGGLE_VIEW: 'TOGGLE_VIEW',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  SET_FIELD_GROUPS: 'SET_FIELDGROUPS',
  SET_SELECTED_SEARCH: 'SET_SELECTED_SEARCH',
  SET_DATE_TO_HUMAN: 'SET_DATE_TO_HUMAN',
  SET_UPPY: 'SET_UPPY',
  SET_COMPLETED_UPLOAD: 'SET_COMPLETED_UPLOAD',
  CLEAR_COMPLETED_UPLOADS: 'CLEAR_COMPLETED_UPLOADS',
  SET_ALERT_DIALOG: 'SET_ALERT_DIALOG',
  SET_SUPPRESS_ALERT: 'SET_SUPPRESS_ALERT',
  TOGGLE_UPLOAD_MODAL: 'TOGGLE_UPLOAD_MODAL',
  TOGGLE_SIDE_DRAWER: 'TOGGLE_SIDE_DRAWER',
  SIDE_DRAWER_UPDATE_SETTINGS: 'SIDE_DRAWER_UPDATE_SETTINGS',
  SET_RESULTS_PER_PAGE: 'SET_RESULTS_PER_PAGE',
  SET_SELECTED_PRESET: 'SET_SELECTED_PRESET',
  SET_DATA_FROM_SEARCH: 'SET_DATA_FROM_SEARCH',
  SET_COPIED_METADATA: 'SET_COPIED_METADATA',
  SET_SHOW_DELETED: 'SET_SHOW_DELETED',
  SET_TITLE_HEIGHT: 'SET_TITLE_HEIGHT',
  SET_NAV_BAR_HEIGHT: 'SET_NAV_BAR_HEIGHT',
  SET_OVERRIDE_SEARCH_LOADING: 'SET_OVERRIDE_SEARCH_LOADING',
  SET_METADATA_VIEW: 'SET_METADATA_VIEW',
  SET_SEARCH_FILTERS_OPEN: 'SET_SEARCH_FILTERS_OPEN',
}

export default Types
